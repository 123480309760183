<template>
    <div class="_w-max">
        <div class="_w-max _mb-pad-top-50 _mb-pad-x-25" style="background-color:rgba(245, 245, 245, 1);">
            <div class=" _mb-mar-bottom-25 _flex-row-bet-mid">
                <div class="_mb-font-48 _b">{{id != 0 ? $t('修改地址') : $t('新建地址')}}</div>
                <!-- <router-link :to="'/user_mobile'" class="_flex-row-mid" style="color:rgba(66, 132, 220, 1);align-self:flex-end;" v-if="userInfo.uid">
                    <img class="_mb-w-25" src="~@/assets/image/hangda/fanhui2.png" alt=""><div class="_mb-font-28 _mb-mar-left-10">{{$t('返回')}}</div>
                </router-link> -->
                <div @click="$router.back()" class="_flex-row-mid" style="color:rgba(66, 132, 220, 1);align-self:flex-end;">
                    <img class="_mb-w-25" src="~@/assets/image/hangda/fanhui2.png" alt=""><div class="_mb-font-28 _mb-mar-left-10">{{$t('返回')}}</div>
                </div>
            </div>
            <div class="_white-bg _mb-font-28 _mb-mar-bottom-40" style="border-radius:6px;">
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('收件人')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="linkman" :placeholder="$t('请输入')" type="text">
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('联系电话')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="mobile" :placeholder="$t('请输入')" type="text">
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25" >
                    <div class="_mb-w-150">{{$t('国家/地区')}}</div>
                    <div class="_flex-row-mid _pos-rel _flex-1" style="justify-content:flex-end;">
                        <div @click="country_show = !country_show;city_show = false" class=" _mb-font-28 _mb-mar-right-10 _flex-1"  type="text">{{area||$t('请选择')}}</div>
                        <div @click="country_show = !country_show;city_show = false" class="iconfont2 icon-xiala"></div>
                        <div class="select-box" v-if="country_show">
                            <div class="li _line-1" v-for="(item,index) in country_list" :key="index" @click.stop="getCountrys(item)">{{item.address_name}}</div>
                        </div>
                    </div>
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25" >
                    <div class="_mb-w-150">{{$t('省/州')}}</div>
                    <div class="_flex-row-mid _pos-rel _flex-1" style="justify-content:flex-end;">
                        <div @click="city_show = !city_show;country_show = false" class=" _mb-font-28 _mb-mar-right-10 _flex-1" type="text">{{province||$t('请选择')}}</div>
                        <div @click="city_show = !city_show;country_show = false" class="iconfont2 icon-xiala"></div>
                        <div class="select-box" v-if="city_show">
                            <div class="li _line-1" v-for="(item,index) in province_list" :key="index"  @click.stop="getProvinces(item)">{{item.label}}</div>
                        </div>
                    </div>
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('城市')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="city" :placeholder="$t('请输入')" type="text">
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('邮编')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="zip_code" :placeholder="$t('请输入')" type="text">
                </div>
                
                <div class="_flex-row-bet-mid _mb-pad-y-25 _mb-pad-x-35">
                    <div class="_mb-w-150">{{$t('详细地址')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="address" :placeholder="$t('请输入')" type="text">
                </div>
            </div>
            <div @click="submit" class="_white _w-max _flex-row-center-mid _mb-font-28 _mb-h-75" style="background-color:rgba(66, 132, 220, 1);border-radius:6px;">{{id != 0 ? $t('修改地址') : $t('新建地址')}}</div>
        </div>
    </div>
</template>

<script>
import { editReceiveAddress, getCountry, getProvinceOption } from '@/api/jiyun.js'
export default {
    name: 'MobileChangeAddress',
    data() {
        return {
            id:0,
            linkman: '',
            mobile: '',
            area_id: 0,
            area: '',
            province: '',
            city: '',
            address:'',
            zip_code: '',
            country_list:[],
            province_list:[],
            country_show:false,
            city_show:false,
        };
    },
    mounted() {
        this.getCountry();
        this.id = this.$route.query.id;
        if(this.id) {
            this.getDetail(this.$route.query)
        }else{
            this.id = 0;
        }
    },
    methods: {
        //获取详情
        getDetail(data){
            this.linkman = data.linkman;
            this.mobile = data.mobile;
            this.area_id = data.area_id;
            this.area = data.area;
            this.province = data.province;
            if(this.area_id) {
                let country = this.country_list.find(item => item.id == this.area_id);
                if(!country){
                    this.$Message.info(this.$t('请重新选择国家或地区'));
                    this.area_id = 0;
                    this.area = '';
                    this.province = '';
                }else{
                    this.getProvince();
                }
            }
            this.city = data.city;
            this.address = data.address;
            this.zip_code = data.zip_code;
        },
        // 提交
        submit(){
            // if(this.userInfo.uid){
            //     let param = {
            //         nickname: this.linkman,
            //         phone: this.mobile,
            //         area_id: this.area_id,
            //         area_name: this.area,
            //         province_id: this.city_id,
            //         province_name: this.city_name,
            //         province: this.province,
            //         address: this.address,
            //         domain_url: window.location.origin,
            //         zip_code: this.zip_code,
            //         city: this.city
            //     }
            //     //修改用户
            //     changeInfo(param).then((res)=>{
            //         this.$Message.success(res.msg);
            //         setTimeout(()=>{
            //             this.$router.go(-1)
            //         },800)
            //     }).catch((err)=>{
            //         this.$Message.error(err.msg);
            //     })
            // }else{
                let param = {
                    id: this.id,
                    linkman: this.linkman,
                    mobile: this.mobile,
                    area_id: this.area_id,
                    area: this.area,
                    province: this.province,
                    city: this.city,
                    address: this.address,
                    zip_code: this.zip_code
                }
                //修改信息
                editReceiveAddress(param).then(res=>{
                    this.$Message.success(this.$t('提交成功'));
                    setTimeout(()=>{    
                        this.$router.go(-1)
                    },800)
                }).catch((err)=>{
                    this.$Message.error(err.msg);
                })
            // }
        },
        // 获取国家列表
        getCountry(){
            getCountry().then((res)=>{
                this.country_list = res.data;
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        // 获取城市列表
        getProvince(){
            let param = {
                pid: this.area_id,
            }
            getProvinceOption(param).then((res)=>{
                this.province_list = res.data;
                if(this.province_list.length <= 0){
                    this.$Message.info(this.$t('请重新选择国家或地区'));
                    this.formData.area_id = 0;
                    this.formData.area = '';
                    this.formData.province = '';
                }
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        // 选择国家
        getCountrys(country) {
            this.country_show = false;
            this.area_id = country.id;
            this.area = country.address_name;
            this.province = '';
            this.city = '';
            this.getProvince();
        },
        // 选择城市
        getProvinces(province){
            if(!this.area_id) return this.$Message.error(this.$t('请先选择国家或地区'));
            this.city_show = false;
            this.province = province.label;
        },
    }
};
</script>

<style scoped>
    .select-box {
        position: absolute;
        width: 100%;
        z-index: 3;
        right: 0;	
        top: 100%;
        background-color: #ffffff;
        overflow-y:scroll;
        min-height: 4.56621vh;
        max-height: 22.83105vh;
        overflow-x: hidden;
        box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
    }
    .select-box .li {
        width: 100%;
        height: 4.56621vh;
        padding: 0px 4vw;
        line-height: 4.56621vh;
        font-size: 3.73333vw;
    }
    .select-box .li:hover {
        border: 1px solid #D6D6D6;
    }
</style>